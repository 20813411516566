import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React, { useRef } from 'react';
import ArticleDiscovery from '../components/ArticleDiscovery';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

function Article({ data, pageContext }) {
  const { body, frontmatter } = data.mdx;
  const hasMorePosts = pageContext.previous || pageContext.next;
  const selectionRef = useRef(null);

  return (
    <Layout>
      <SEO title={frontmatter.title} description={frontmatter.title} />
      <TitleWrapper>
        <Title>{frontmatter.title}</Title>
        <Subtitle>{frontmatter.date}</Subtitle>
      </TitleWrapper>
      <div ref={selectionRef}>
        <MDXRenderer>{body}</MDXRenderer>
      </div>
      {hasMorePosts && (
        <ArticleDiscovery
          previous={pageContext.previous}
          next={pageContext.next}
        />
      )}
    </Layout>
  );
}

export const query = graphql`
  query PostsBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        date(formatString: "MMMM Do, YYYY")
        title
      }
    }
  }
`;

const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 2rem;
`;

const Title = styled.h1`
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-family: var(--font-serif);
  @media (min-width: 768px) {
    font-size: 3rem;
    line-height: 3.5rem;
  }
`;

const Subtitle = styled.div`
  padding-top: 1.25rem;
  margin-bottom: 1rem;
  color: var(--color-muted);
`;

export default Article;
